import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-f8393398"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page_container_with_720" }
const _hoisted_2 = { id: "public_welfare_des" }
const _hoisted_3 = { id: "news_details_content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BoxWithPublicNews = _resolveComponent("BoxWithPublicNews")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.info.content), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.info.children, (item, index) => {
        return (_openBlock(), _createBlock(_component_BoxWithPublicNews, {
          key: `news_${index}`,
          item: item,
          id: "public_welfare_box"
        }, null, 8, ["item"]))
      }), 128))
    ])
  ]))
}